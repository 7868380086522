<template>
    <v-card flat class="editable-price-list">

        <div v-if="items && items.length > 0">

            <h4 class="mb-3">Line Items</h4>

            <v-row v-for="(item, index) in items" :key="index">
            <v-col cols="4" class="py-2">
                <output>{{ item.Key }}</output>
            </v-col>
            <v-col cols="2" class="py-2">
                <output>{{ Display.Money(item.Value) }}</output>
            </v-col>
            <v-col cols="2" class="mr-auto py-2">
                <v-btn color="secondary" x-small @click="RemoveItem(item.Id)">Remove</v-btn>
            </v-col>
        </v-row>
        </div>

        <div v-else>

            <v-row>
                <v-col cols="4">
                    <h4 class="mb-3">Line Items</h4>
                    <output>No items</output>
                </v-col>
            </v-row>
        </div>

        <v-divider class="mt-5 mb-5" />

        <v-form ref="addPrice">
        <v-row>
            <v-col cols="4" >
                <v-text-field
                    label="Item Description"
                    prefix=""
                    type="text"
                    dense
                    outlined
                    flat
                    v-model="newItem"
                    :rules="[validators.required]"
                    ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    label="Price"
                    prefix="£"
                    type="number"
                    dense
                    outlined
                    flat
                    v-model="newPrice"
                    :rules="[validators.requiredNumber, validators.integerRange(newPrice, 0, 10000)]"
                    ></v-text-field>
            </v-col>
            <v-col cols="4" >
                <v-btn color="primary" @click="AddItem">Add Line Item</v-btn>
            </v-col>
        </v-row>
        </v-form>

    </v-card>
</template>

<script>
import * as Validators from '@/datahelpers/validation'
import * as FrameworkValidators from '@core/utils/validation'
import * as Display from '@/datahelpers/displayTypes'

export default {
    props: {
        items: {
            type: Array,
            default: function() { return []; }
        },
    },
    data: function() {
        return {
            newPrice: null,
            newItem: "",
            validators:  {
                ...FrameworkValidators,
                ...Validators
            },
            Display
        }
    },
    methods: {
        AddItem() {
            if (this.$refs.addPrice.validate()) {
                // this.value.push({key: this.newItem, value: this.newPrice});
                // this.$emit('input', this.value);
                this.$emit('addItem', {Id: this.uuidv4(), Key: this.newItem, Value: parseFloat(this.newPrice)});
                this.$refs.addPrice.reset();
                this.newPrice = "";
                this.newItem = "";
            }
        },
        RemoveItem(Id) {
            this.$emit('removeItem', Id);
        },
        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }
    }
}
</script>